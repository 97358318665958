export const headMeta = {
  SDK: {
    name: "be:sdk",
  },
  SDK_TYPE: {
    name: "be:sdk_type",
  },
  TIMER: {
    name: "be:timer",
  },
  ORIG_URL: {
    name: "be:orig_url",
  },
  NORM_URL: {
    name: "be:norm_url",
  },
  CAPSULE_URL: {
    name: "be:capsule_url",
  },
  API_DT: {
    name: "be:api_dt",
  },
  MOD_DT: {
    name: "be:mod_dt",
  },
  MESSAGES: {
    name: "be:messages",
  },
  IS_SPA: {
    name: "be:is_spa",
  },
  CONTENT_ONLY: {
    name: "be:content_only",
  },
};

export const scriptClass = {
  HEAD: {
    name: "be:head_script",
  },
  BODY: {
    name: "be:body_script",
  },
  COMMENT: {
    name: "be:comment_script",
  },
};

export const styleClass = {
  LINK_STYLE: {
    name: "be:link_style",
  },
};

export const SELECTORS = {
  LINK_BLOCK_CONTAINER: {
    selector: ".be-ix-link-block," +
      "#be-ix-link-block," +
      ".be-ixf-link-block," +
      ".BrightEdgeRelatedLinks," +
      ".be-related-link-container," +
      ".o-AutoPilot__m-AutoPilot-Wrap",
    name: "link-block-container",
  },
};
